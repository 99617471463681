// extracted by mini-css-extract-plugin
export var additionalPadding = "LeadConfirmed__additionalPadding__YvStQ";
export var column = "LeadConfirmed__column__fGmio";
export var flex = "LeadConfirmed__flex__bAec5";
export var flexColumn = "LeadConfirmed__flexColumn__iYDUS";
export var gap1 = "LeadConfirmed__gap1__K48kU";
export var gap2 = "LeadConfirmed__gap2__Qs5Tt";
export var gap3 = "LeadConfirmed__gap3__jSF7N";
export var gap4 = "LeadConfirmed__gap4__g61St";
export var gap5 = "LeadConfirmed__gap5__XnrKA";
export var layout = "LeadConfirmed__layout__zpc8l";
export var messageContainer = "LeadConfirmed__messageContainer__uH3PK";
export var row = "LeadConfirmed__row__P7Lzb";